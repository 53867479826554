*{
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
}


.navbar{
    display: flex;
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    background-color: #1B1212;
    color: white;
    position: sticky
}

.name {
    font-size: 30px;
    height: 100px;
    margin-left: 15px;
    font-weight: bold;
    position: sticky;
}

.name > p > em{
    color: #3f8be2;
}


.navbar-links ul {
    height: 50px;
    align-items: center;
    display: flex;
    font-size: 20px;
    font-weight: bold;
    position: sticky;
}

.navbar-links li{
    list-style: none;
}

.navbar-links li a {
    text-decoration: none;
    color: white;
    padding: 1rem;
    display: block;
}

.navbar-links li:hover {
    background-color: #555;
} 