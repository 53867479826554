.project{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}

.myProjects{
    color: white;
}

.pacman{
    display: flex;
    align-items: center;
    gap: 75px;
    justify-content: center;
    margin-top: 50px;
}

.pacman-logo{
    height: auto; 
    width: auto; 
    margin-left: 25px;
    max-width: 450px; 
    max-height: 450px;
}

.donkeykong{
    display: flex;
    align-items: center;
    gap: 75px;
    justify-content: center;
    margin-top: 50px;
}

.donkeykong-logo{
    height: auto; 
    width: auto; 
    margin-left: 25px;
    max-width: 450px; 
    max-height: 450px;
}

.minesweeper{
    display: flex;
    align-items: center;
    gap: 75px;
    justify-content: center;
    margin-top: 50px;
}

.minesweeper-logo{
    height: auto; 
    width: auto; 
    margin-left: 25px;
    max-width: 450px; 
    max-height: 450px;
}

.asteriods{
    display: flex;
    align-items: center;
    gap: 75px;
    justify-content: center;
    margin-top: 50px;
}

.asteriods-logo{
    height: auto; 
    width: auto; 
    max-width: 450px; 
    max-height: 450px;
}

.flappyBird{
    display: flex;
    align-items: center;
    gap: 75px;
    justify-content: center;
    margin-top: 50px;
}

.flappyBird-logo{
    height: auto; 
    width: auto; 
    max-width: 450px; 
    max-height: 450px;
}

.pong{
    display: flex;
    align-items: center;
    gap: 75px;
    justify-content: center;
    margin-top: 50px;
}

.pong-logo{
    height: auto; 
    width: auto; 
    max-width: 450px; 
    max-height: 450px;
}

.icon-logo{
    height: auto; 
    width: auto; 
    max-width: 25px; 
    max-height: 25px;
}

.project-link ul{
    height: 50px;
    align-items: center;
    display: flex;
    font-size: 20px;
    font-weight: bold;
    position: sticky;
}

.aligned span{
    padding: 10px;
}

.project-link li{
    list-style: none;
    display: flex;
}

.project-link li a {
    text-decoration: none;
    color: white;
    padding: 1rem;
    display: block;
}

.project-link li:hover {
    background-color: #555;
} 
